import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import CertificateDetailsPage from '../../components/CertificateDetailsPage';

const CertificatesRouter = (props) => (
  <Router>
    <CertificateDetailsPage
      {...props}
      path="/certificates/:certificatePolicyNumber"
    />
    <NotFoundPage default />
  </Router>
);

export default CertificatesRouter;
