import PropTypes from 'prop-types';
import Card from '@shoreag/base/Card';
import React from 'react';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import Spinner from '@shoreag/base/Spinner';
import { parse } from 'query-string';
import Route from '../Route';
import allPolicyCertificate from '../../graphql/queries/all-policy-certificate.gql';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import KeyValuePairs from '../KeyValuePairs';
import dashboardConstants from '../../utilities/dashboard-constants';

const CertificateDetailsPage = ({ location, certificatePolicyNumber }) => {
  const masterPolicyNumber = get(
    parse(location.search),
    'masterPolicyNumber',
    null
  );

  const { pageCriteria } = dashboardConstants.getCriterias({
    type: 'policy_certificate',
  });

  return (
    <Route
      header={{
        icon: 'master-policy',
        title: certificatePolicyNumber,
        type: 'policy certificate',
      }}
      isPrivate
    >
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allPolicyCertificate}
        variables={{
          searchBody: JSON.stringify({
            ...{
              certificatePolicyNumber: {
                type: 'Input',
                valueType: 'S',
              },
              values: { certificatePolicyNumber },
            },
            ...{
              masterPolicyNumber: {
                type: 'Input',
                values: masterPolicyNumber,
                valueType: 'S',
              },
            },
            operation: 'query',
            ...pageCriteria,
          }),
        }}
      >
        {({ data, loading }) => {
          const allPolicyCertificate = get(data, 'allPolicyCertificate', []);
          const datasetEntries = loading
            ? {}
            : Object.entries(allPolicyCertificate.certificates[0]);
          return loading ? (
            <Card m={0} p={5}>
              <Spinner py="md" />
            </Card>
          ) : (
            <>
              <Card sx={{ position: 'relative' }}>
                <h2>Overview</h2>
                <KeyValuePairs
                  autoTitleCase={false}
                  pairs={datasetEntries.map(enhanceKeyValuePairEntries)}
                />
              </Card>
            </>
          );
        }}
      </Query>
    </Route>
  );
};

CertificateDetailsPage.propTypes = {
  certificatePolicyNumber: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

CertificateDetailsPage.defaultProps = {
  certificatePolicyNumber: null,
};

export default CertificateDetailsPage;
